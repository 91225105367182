import React from 'react'
import './PrivacyPolicyPage.css'

export default function (props) {


    return (
        <div>
            <h1>Privacy Policy</h1>
            <p>
                This Privacy Policy explains the information that Dolphin Reader may collect when you interact with us and our practices with respect to that information. Please read this Privacy Policy carefully. In addition, please review the applicable terms of use for the Properties, which governs your use of the properties and services.
            </p>
            <h3>1. Introduction</h3>
            <p>
                Your service provider is D.Reader Inc., a company operating under the laws of The USA, located at 221 N. Broad Street Suite 3A Middletown, DE, USA 19709. We recognizes that your privacy is very important, and we take it seriously. Your privacy and trust are very important to us, and it is our commitment to being transparent about the use of collected information.
            </p>
            <p>
                This Privacy Policy describes D.Reader’s policies and procedures on the collection, use, disclosure, and sharing of your personal information or personal data, and the choices you can make about how we use this information when you access or use our Site, Application, and/or Services.
            </p>
            <p>
                Note that D.Reader’s Terms of Service  apply to this Privacy Policy. By accessing or using the D.Reader  application, you are consenting that you unconditionally accept this Privacy Policy. If you do not agree to the terms found on this Privacy Policy, please do not use the D.Reader Services.
            </p>
            <p>
                <strong>THIS PRIVACY POLICY IS IMPORTANT. WE HOPE YOU WILL TAKE THE TIME TO READ IT CAREFULLY.</strong>
            </p>

            <h3>2. Age restrictions</h3>
            <p>
                DolphinReader is not intended for children. Children under the age of 13 must not use our Site, Application, and/or Services for any purpose. Dolphin Reader will never knowingly solicit or accept personally identifiable information or other content from a user or visitor who DolphinReader knows is under 13 years of age. If Dolphin Reader discovers that a user under 13 years of age has created an account, or that a user or visitor under 13 years of age has posted personally identifiable information or other content to the Service, Dolphin Reader will terminate the account and remove the information or other content.
            </p>
            <p>
                Users between 13 and 18 (each a “Teen”) may not access or use the Service unless (i) both the Teen and their parent or legal guardian have first agreed to these Terms of Service; and (ii) the Teen uses an account established by their parent or legal guardian, under such parent or guardian’s supervision, and with such parent or guardian’s permission.  If you permit a Teen to use the Services, you hereby agree to these Terms of Service on behalf of both yourself and the Teen. You further agree that you are solely responsible for any and all use of the Service by your Teen regardless of whether such use was authorized by you.
            </p>

            <h3>3. Data Collection</h3>
            <p>
                In order to help you to create an account with Dolphin Reader and use our Platform, we need to collect and process some Personal Information. "Personal Information" is any information that can identify you. This may include but not limited to your name, mailing address, telephone number, email address, Internet Protocol (“IP”) address, age, gender, credit card information. We use the Personal Information to:
            </p>
            <p className='p-detail'>
                Create your Dolphin Reader account and provide you with our Services;
                <br />
                Identify you across the Platform;
                <br />
                Make age appropriate content available to you;
                <br />
                Provide you with technical support services;
                <br />
                Communicate with you (e.g. letting you know about upcoming changes or improvements);
                <br />
                Send you marketing emails, unless you opt-out;
                <br />
                Calculate anonymous and aggregate statistics to better understand our users, improve our services, develop new features, personalize and customize your story discovery experience;
                <br />
                Help prevent spam, fraud, abuse, and other prohibited activity;
                <br />
                Display relevant, tailored advertisements and improve your ad experience, except where your separate consent is required;
                <br />
                For security purposes, other purpose aims to operate, develop and improve our services.
            </p>

            <h3>4. Information Sharing</h3>
            <p><strong>4.1</strong> We treat the personal information above as private by default, so it won’t be displayed on the Platform (unless you choose to publicly display applicable information via your account settings) or revealed to other DolphinReader users unless the data has been anonymized.</p>
            <p><strong>4.2</strong> Our Platform are designed to help you share information with the DolphinReader community. Any personal information that you voluntarily choose to include in a publicly accessible space of the Platform will be available to anyone who has access to that content (including other DolphinReader users), such as your username, name, the description you create, and your location, as well as the messages you post whether on your profile, on our forums or to other users, and the lists you create, people you follow, stories you write, and many other bits of information that result from your use of the Platform.</p>
            <p><strong>4.3</strong> We do not rent or sell your personally identifying information to other companies or individuals, unless we have your consent, except in the following very limited situations:</p>
            <p className='p-detail'>
                To comply with an applicable law (including investigations of any crime), regulation, legal process or enforceable governmental request;
                <br />
                To enforce or apply our Terms of Use and other policies, including investigation of potential violations;
                <br />
                To protect the rights, property, or safety of DolphinReader, DolphinReader’s employees and users, or the public.
            </p>

            <h3>5. Information Security</h3>
            <p>
                We are continuously implementing and updating physical, organizational, contractual and technological security measures to protect your Personal Information from loss, theft, unauthorized access, disclosure, copying, use or modification. The only employees who are granted access to your Personal Information are those with a business “need-to-know” or whose duties reasonably require such information. On all DolphinReader platforms, we support encryption on all transmissions to protect Personal Information submitted on our Platform.
            </p>

            <h3>6. Non-personal Information</h3>
            <p><strong>6.1</strong> DolphinReader uses cookies, tracking pixels and similar technologies on our Platform to collect information that cannot identify you as an individual (“non-personal information”) that helps us provide our Service to you and to learn how our Service is used. We also use these technologies to help deliver promotional messages and ads for relevant Goodnovel products and services.</p>
            <p><strong>6.2</strong> We may share non-personal information publicly and with various third parties like publishers or advertisers. For example, we may share information with advertisers or publicly show trends about the general use of our Platform.</p>

            <h3>7. Third-party Advertisers, Links to Other Platforms</h3>
            <p><strong>7.1</strong> DolphinReader allows other companies, which are known as third-party ad servers or ad networks, to serve advertisements within DolphinReader’s Platform. These third-party ad servers or ad networks use technology to send the advertisements and links that appear on DolphinReader directly to you. They automatically receive your IP address when the advertising happens. They may also use other technologies (such as cookies, JavaScript, or Web Beacons) to measure the effectiveness of their advertisements and to personalize the advertising content you see.</p>
            <p><strong>7.2</strong> DolphinReader does not provide any personally identifiable information to these third-party ad servers or ad networks without your consent or except as part of a specific program or feature for which you will have the ability to opt-in or opt-out.</p>
            <p><strong>7.3</strong> You should consult the respective privacy policies of these third-party ad servers or ad networks for more information on their practices and for instructions on how to opt-out of certain practices. DolphinReader’s Privacy Policy does not apply to, and we cannot control the activities of, such other advertisers or platforms. Any data obtained by third-party ad servers and subsequently shared with DolphinReader is maintained and dealt with by DolphinReader in accordance with this Privacy Policy.</p>

            <h3>8. Policy Governs Use: Changes to Privacy Policy</h3>
            <p>
                D.Reader reserves the right to change this Privacy Policy at any time, without prior notice to you, and any changes will become effective immediately upon posting, so please review this Privacy Policy periodically.
            </p>

            <h3>9. How users to request data deletion</h3>
            <p><strong>Step.1:</strong>&nbsp;Launch Dolphin Reader, tap the 4th tab to enter the User page;</p>
            <p><strong>Step.2:</strong>&nbsp;Be sure that your account is signed in with Google, Facebook or AppleID and not a Guest nor a Visitor;</p>
            <p><strong>Step.3:</strong>&nbsp;Tap the gearwheel like icon to enter the Setting page. Find the red button to delete the account;</p>
            <p><strong>Step.4:</strong>&nbsp;Learn the consequence of this operation. Then tap Delete to confirm, tap Cancel otherwise;</p>
            <p><strong>Step.5:</strong>&nbsp;Wait for the response from the server, and the account will be logged out automatically. </p>
            <p>That’s all.</p>

            <h3>10. Survey Serving Technology</h3>
            <p>
                This app uses Pollfish SDK. Pollfish is an on-line survey platform, through which, anyone may conduct surveys. Pollfish collaborates with Publishers of applications for smartphones in order to have access to users of such applications and address survey questionnaires to them. When a user connects to this app, a specific set of user's device data (including Advertising ID, Device ID, other available electronic identifiers and further response meta-data is automatically sent, via our app, to Pollfish servers, in order for Pollfish to discern whether the user is eligible for a survey. For a full list of data received by Pollfish through this app, please read carefully the Pollfish respondent terms located at <a href="https://www.pollfish.com/terms/respondent">https://www.pollfish.com/terms/respondent</a>. These data will be associated with your answers to the questionnaires whenever Pollfish sends such questionnaires to eligible users. Pollfish may share such data with third parties, clients and business partners, for commercial purposes. By downloading the application, you accept this privacy policy document and you hereby give your consent for the processing by Pollfish of the aforementioned data. Furthermore, you are informed that you may disable Pollfish operation at any time by visiting the following link <a href="https://www.pollfish.com/respondent/opt-out">https://www.pollfish.com/respondent/opt-out</a>. We once more invite you to check the Pollfish respondent's terms of use, if you wish to have more detailed view of the way Pollfish works and with whom Pollfish may further share your data.
            </p>
            
            <h3>11. Contact us</h3>
            <p>
                If you have any questions or concerns about this policy, You may email us at the following email addresses: <a href="">dolphinreader2021@gmail.com</a>
            </p>
            <br />
            <p></p> 
        </div>
    )
}