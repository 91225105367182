import React from "react";
import { Typography, Space } from 'antd';
import './DBPolicy.css'

const { Text, Link } = Typography;
function DBPolicy() {

    return (
        <div>
            <p>
                We respect the privacy of every individual users of our applications and services.
            </p>
            <p>
                This Privacy Policy ("Policy") describes how Dolphin Inc ("Dolphin", "We", "Us", or "Our") protects your privacy when you use Dolphin applications on all platforms ("App", "Apps", "Application" or "Applications") and the related services we provide ("Service" or "Services").
            </p>
            <p>
                This Policy covers our collection, use and disclosure of your information through the Applications and the Services. It does not cover any collection, use or disclosure by third parties through any applications, web sites, products or services that we do not control or own, such as Facebook, or any third-party features or services made available via the Application or the Services.
            </p>
            <p>
                PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY INSTALLING AND/OR USING THE APPLICATION AND/OR SERVICE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS DESCRIBED HEREIN AND ALL TERMS AND CONDITIONS INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS SET FORTH BELOW, YOU MAY NOT USE THE LIONMOBI APPLICATIONS AND/OR SERVICES.
            </p>
            <h2>Privacy Policy</h2>
            <p>Last modified: Oct. 1, 2021</p>
            <p>This Privacy Policy is meant to help you understand:</p>
            <p className='p-detail'>
                what data we collect and why we collect that information.
                <br />
                how the data we collected is used and shared.
                <br />
                information security.
                <br />
                international users.
                <br />
                sensitive information.
                <br />
                changes to this Privacy Policy.
            </p>
            <p>
                We've tried to keep the Policy simple, but if you have any questions on the privacy or our privacy practices, or to report any violations of the Policy or abuse of the Applications or the Services, please feel free to contact us via email: contact@dolphinreader.com.
            </p>
            <h3>
                What Information We Collect?
            </h3>
            <p>
                We collect personal information to implement significant features and provide you better experience. This information includes:
            </p>
            <p className='p-detail'>
                Device Information. We collect device-specific information (such as your hardware manufacturer {"&"} model, operating system version, screen size, CPU model, unique device identifiers, mobile network information and installed apps) to implement app features, provide our services, and improve the app performance for all our users.
                <br />
                Call, Camera {"&"} Storage. We use this information only for the proper operation of some functions, we do not use it personally.
                <br />
                App Usage Data. Information about your usage of the app. We use this information to improve the user experience. This information may be collected by trusted third-party analytics.
                <br />
                Google Advertising ID. Google Advertising ID by Google Play Service for advertising is used in the third-party advertising network to provide personalized advertisements and prevent inappropriate advertisement content for you.
                <br />
                Geographic Location. We may collect information about your location if it is required to provide the needed functionality of the product or service or to implement features (such as Nearby Ranking). Please note that we do not use your location data to track your footprints.
                <br />
                Aggregate Information. We collect about a group or category of services or users, which means information on how you use the Applications and the Services may be collected and combined with information about how others use the Applications and the Services.
                Social Media
            </p>
            <p>
                If you choose to access third party Social Media Web sites and services through our app, you will be sharing information with those Social Media Services, and the information you share will be governed by their privacy policies and terms of service.
            </p>
            <h3>How We Use Information We Collect?</h3>
            <p>
                We use the information we collect from our applications and services to provide you better features and experience on them. We also use the data to measure the performance and improve the apps and services.
                <br />
                We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.
            </p>
            <p>
                Opt-out of personalized advertising.
            </p>
            <p>
                To show you personalized advertisements in our apps we use specific advertising networks and their partners to deliver advertisements that are tailored to you based on a determination of your characteristics or interests. To do so they use personal and non-personal information such as advertising identifiers, such as the Android advertising ID, and/or other tracking technologies to enable and optimize this advertising procedure.
                <br />
                You can opt-out from personalized advertisement experience, at any time by checking the privacy settings of your Android device and selecting "opt-out of interest-based ads" (Android). When you choose to opt-out, advertising networks will consider this choice as a withdrawal of consent to personalized advertisement experience and they will show only non-personalized advertisements and not targeted advertisements based on your interests.
            </p>
            <h3>Information Security</h3>
            <p>
                We work hard to protect your information from unauthorized access by providing administrative, technical, organizational, physical, electronic, and procedural safeguards and taking risk management measures in accordance with applicable laws to ensure your data is adequately protected against accidental or unlawful destruction, accidental loss, alteration, unauthorized disclosure, or access, use, and all other unlawful forms of processing of your data in our possession. We restrict access to personal and/or sensitive data only to highly related employees, contractors and agents who need to know that information for operation, development and/or improvement purposes. Please be aware that, although we endeavor to protect the information we collect and store, no security system guarantees absolute safety.
            </p>
            <h3>International Users</h3>
            <p>
                Your personal information may be stored and processed in any country where we have facilities, and by using the Applications or the Services you consent to the transfer of your personal information to countries, which may be outside of your country of residence and may provide for different and less stringent data protection rules than in your country. If you object to your personal information being transferred or used as described in this Policy, please do not use the Applications or the Services and immediately delete the Applications from your Devices.
            </p>
            <h3>Sensitive Information</h3>
            <p>
                We ask that you do not send us, and you do not disclose, any sensitive personal information (e.g., information related to racial or ethnic origin, political opinions, religion or other beliefs, health, sexual orientation, criminal background or membership in past organizations, including trade union memberships) on or through the Application or the Services or otherwise to us.
            </p>
            <h3>Changes to This Privacy Policy</h3>
            <p>
                Our Privacy Policy may change from time to time. We do not reduce your rights under this Privacy Policy without your explicit consent. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice. We will also keep prior versions of this Privacy Policy in an archive for your review.
            </p>
        </div>

    )
}

export default DBPolicy