import React, { useEffect, useState, useRef } from "react";
import './HomePage.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import ContactUs from "./element/ContactUs";

export default function (props) {

    const [bannerUrl, setBannerUrl] = useState("../../top-banner-bg640x320.jpg");

    let a = "Dolphin Reader - Romantic Books & Fantasy Novels for Story lovers!";
    let b = "Collection of Best Books & Hottest New Reads in Every Genre!";
    const sloganStr = "Dolphin Reader - Romantic Books & Fantasy Novels for Story lovers!";
    let appDesc = (
        <div id='app-desc-root'>
            <p>{a}</p>
            <p>- Book Genres: Romance, Mystery, Fantasy, Western, Werewolf/Vampire <br />
                {b}
            </p>
            <p>Start reading your favorite book, by downloading the Dolphin Reader app</p>
            <p>- The popular story Reading Platform for Novel Lovers
                <br />
                - Numerous of Book Genres for Choosing Freely</p>
            <p>Dolphin Reader has collected popular web novels and books, provides you with the latest chapters of the highest quality.</p>
        </div>
    )

    return (
        <Router>
            <div>
                <div className='home-page-root'>
                    {/* <div id='top-banner' style={{background:{bannerBgUrl}}}> */}
                    <div id='top-banner' >
                        <img style={{ width: "100%", minWidth: "500px" }} id="img-banner-bg" src="../../top-banner-bg820x312.jpg" />
                        <div id='top-left-name'>D.Reader Inc</div>
                        <img className='top-left-icon' src="../../logo152gray-alpha.png" alt="" />
                        <a className='home-a' href="/">Home</a>
                        <Link className='contactus-a' to="/contactus">Contact us</Link>
                        {/* <a id='a-p-p' href="/privacypolicy">Privacy Policy</a> */}
                    </div>


                    <p className="p-slogan">{sloganStr}</p>
                    <Switch>
                        <Route exact path="/" >
                            <div>
                                <div className='img-body'>
                                    <div className='img-root' align="center">
                                        <img id='big-main-phone' src='../../phone-img-alpha.png' />
                                        <a href="https://apps.apple.com/us/app/id1569280416">
                                            <img className="download-img" src="../../get_it_on_app_store.png" />
                                        </a>
                                        <br />
                                        <img className="download-img" src="../../get_it_on_google_play.png" />

                                    </div>
                                    {appDesc}
                                </div>

                            </div>

                        </Route>
                        <Route exact path="/home" >
                            <div>
                                <div className='img-body'>
                                    <div className='img-root' align="center">
                                        <img id='big-main-phone' src='../../phone-img-alpha.png' />
                                        <a href="https://apps.apple.com/us/app/id1569280416">
                                            <img className="download-img" src="../../get_it_on_app_store.png" />
                                        </a>
                                        <br />
                                        <img className="download-img" src="../../get_it_on_google_play.png" />

                                    </div>
                                    {appDesc}
                                </div>

                            </div>

                        </Route>
                        <Route exact path="/contactus" component={ContactUs}>

                        </Route>

                    </Switch>




                    <div className='bottom-link' align="center">
                        <span className="bottom-normal-left-text">© 2021 D.Reader Inc. All Rights Reserved. <br />成都多峰科技有限公司 </span>
                        <a href="https://www.facebook.com/Dolphin-Reader-105766245084210">
                            <img style={{width:"32px",height:"32px"}} src="../../facebook.png" />
                        </a>
                        <a className='bottom-a' href="/privacypolicy">
                            Privacy Policy
                        </a> 
                        <a className='bottom-b' href="/termsofservice">
                            Terms of Service
                        </a>
                        <br />
                        <br />
                    </div>
                </div>
                <div className='home-page-root2'></div>
            </div>
        </Router>
    )

}


// .home-page-root{
//     background: rgb(0, 0, 0);
//     /* // background-image:url("./loginbg2.jpg"); */
//     background-repeat:no-repeat; 
//     /* // background-size:100% 100%; */
//     background-position: center;
//     background-attachment: fixed;
//     background-size:cover;
//     -moz-background-size:100% 100%;
//     /* // -moz-background-size: cover; */
//     -webkit-background-size: cover;
//     -o-background-size: cover;
//     height: auto;
//     width: auto;
//     position: absolute;
//     left: 0px;
//     top: 0px;
//     right: 0px;
// }