import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    BrowserRouter
} from "react-router-dom";
import CancellationSteps from "./page/CancellationSteps";
import DBPolicy from "./page/DBPolicy";
import HomePage from "./page/HomePage";
import PrivacyPolicyPage from "./page/PrivacyPolicyPage";
import SupportPage from "./page/SupportPage";
import TermsOfServicePage from "./page/TermsOfServicePage";

export default function () {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={HomePage}/>
                <Route exact path="/home" component={HomePage}/>
                <Route exact path="/privacypolicy" component={PrivacyPolicyPage}/>
                <Route exact path="/termsofservice" component={TermsOfServicePage}/>
                <Route exact path="/support" component={SupportPage}/>
                <Route exact path="/contactus" component={HomePage}/>
                <Route exact path="/delete-account-data" component={CancellationSteps}/>
                <Route exact path="/dolphintool-privacypolicy" component={DBPolicy}/>
                <Route exact path="/dolphintool-privacypolicy" component={DBPolicy}/>
                <Route exact path="/tool/privacypolicy" component={DBPolicy}/>
            </Switch>
        </Router>
    )


}