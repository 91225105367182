import React from 'react'
import './TermsOfServicePage.css'

export default function (props) {
    return (
        <div>
            <h1>Terms Of Service</h1>
            <p>D.Reader Inc., registered in USA (DolphinReader, “we”, “our”), provides a Platform (as defined below) for writers and publishers to distribute and monetize various literary works (a “Work” or “Works”). These TERMS OF SERVICE, together with all documents referred to herein and all other operating rules, policies and procedures that DolphinReader may publish from time to time, govern DolphinReader’s relationship with users (“users”, “you”) of the DolphinReader platform located at the website www.dolphinreader.com, including all sub-domains and associated websites (collectively, the “Website”), the DolphinReader mobile application(s), including iPhone and Android mobile applications (“Apps”), any and all other platforms on which content is accessed, published, or distributed, and all associated services, features, or content made available through any of the foregoing (“Services”) (together, the “Platform”). The Terms constitute a legally binding agreement between DolphinReader and you.</p>
            <p>By accessing or using the Platform, you confirm that you unconditionally accept these Terms and that you agree to fully comply with them. Please read these Terms carefully before using the Platform. If you do not agree to these Terms, please do not use the Platform and you are not granted permission to access, view, or otherwise use the Platform for any purpose.</p>
            <p>The Platform may contain links or embedded material to third party content, which DolphinReader has not reviewed, including, without limitation, other websites and third party services, for the convenience of visitors, advertising purposes, or for other similar functions related to DolphinReader’s business. DolphinReader linking or embedding to third party content does not imply an advertisement or endorsement of any good, service, product, or otherwise, provided by such third party. DolphinReader is not responsible for any third party content linked or embedded to or from the Platform and expressly disclaims, without limitation, any responsibility for any third party content, the accuracy of any information found on any third party website, or the quality of products of services provided by or advertised on such third party website.</p>
            <p>DolphinReader may add to, change, or remove any part of this Terms of Use at any time, without notice. Any changes to this Terms of Use or any terms posted on this Site apply as soon as they are posted. By continuing to use this Site after any changes are posted, you are indicating your acceptance of those changes. DolphinReader may add, change, discontinue, remove, or suspend any other Content posted on this Site, including Works described or depicted on the Site, temporarily or permanently, at any time, without notice and without liability.</p>

            <h3>1. Eligibility</h3>
            <p>You must be at least 13 years of age to use the Platform. By agreeing to these Terms, you represent and warrant to us that: (a) you are at least 13 years of age; (b) you have not previously been suspended or removed from the Service; and (c) your registration and your use of the Platform is in compliance with all applicable laws and regulations. If you are using the Service on behalf of an entity, organization, or company, you represent and warrant that you have the authority to bind that organization to these Terms and you agree to be bound by these Terms on behalf of that organization.</p>

            <h3>2. License to Users</h3>
            <p><strong>2.1</strong> Subject to your full compliance with the Terms and to the extent we are lawfully able to grant such rights, DolphinReader grants you a non-exclusive, non-sublicensable and non-transferable license to the Intellectual Property solely for the purposes of accessing Works, using the Platform, and for other purposes expressly stated herein.</p>
            <p><strong>2.2</strong> For the avoidance of doubt, the Terms do not transfer from us to you any of our, or any third party’s intellectual property rights, and all right, title and interest in and to such property will remain vested with the applicable owner.</p>

            <h3>3. Registration and Accounts</h3>
            <p><strong>3.1</strong> To use certain features of the Platform, you are required to register for a DolphinReader account (an “Account”). You agree that all information that you submit during the Account signup process, or additional information you submit to your Account profile at any time thereafter, shall be accurate and truthful, and you shall keep the same up to date.</p>
            <p><strong>3.2</strong> Your Account is unique and personal to you, and you are responsible for maintaining the security of, and restricting access to, your account and password, and you agree to accept responsibility for all purchases and other activities that occur under your Account.</p>
            <p><strong>3.3</strong> You have the ability to delete your Account, and you should understand that upon deletion of your Account, you will lose the right to access or use all or part of the Platform.</p>
            <p><strong>3.4</strong> We reserve the right to disable, suspend, or terminate your Account, at any time, for any reason in our discretion, especially when you fail to comply with any provision of these Terms.</p>

            <h3>4. Content Policy</h3>
            <p><strong>4.1</strong> Our content policy, (“Content Policy”), sets out, among other things, the terms on DolphinReader’s uses of Content which writers and publishers upload to the Platform. You must review and agree to the Content Policy prior to uploading any Works on the Platform.</p>
            <p><strong>4.2</strong> For the avoidance of doubt, all rights not expressly granted to DolphinReader herein shall be reserved to you and DolphinReader is not claiming any ownership in and to your Content.</p>

            <h3>5. Comments on Platform</h3>
            <p><strong>5.1</strong> DolphinReader welcomes your interaction on the Platform, and you may be able to submit materials to DolphinReader which are not Content. By submitting any comments or feedback (together, a “Comment” or “Comments”) through the Platform or otherwise, such as emailing DolphinReader about the Platform, posting on a message board, or reviewing a Work, DolphinReader will be free to use the Comment for any purpose, in all media, in perpetuity, without owing any obligation or liability to you whatsoever. If you wish to keep a Comment private or proprietary, please do not transmit any Comment to DolphinReader or share any Comment with others.</p>
            <p><strong>5.2</strong> Comments must not include or embody, in whole or part, (a) infringe, violate, or misappropriate any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (b) slander, defame, libel, or invade the right of privacy, publicity or other property rights of any other person; or (c) any other DolphinReader policies or guidelines to which your User Content is subject; (d) cause DolphinReader to violate any law or regulation.</p>
            <p><strong>5.3</strong> Comments found to be in violation of any of the above may subject you to the termination or suspension of your Account or the immediate removal of the Comments from the Platform, without notice to you, in our sole discretion.</p>

            <h3>6. Restricted User Conduct</h3>
            <p><strong>6.1</strong> Except and solely to the extent such a restriction is impermissible under applicable law, you may not: (a) reproduce, distribute, publicly display, or publicly perform the Service; (b) make modifications to the Service; or (c) interfere with or circumvent any feature of the Service, including any security or access control mechanism. If you are prohibited under applicable law from using the Service, you may not use it.</p>
            <p><strong>6.2</strong> You will not alter or modify the Platform in any way nor use other technology or means to access the Platform except for the means authorized by DolphinReader, including, without limitation, using no bots, spiders, or other automated means of accessing the Platform.</p>

            <h3>7. Payment Terms</h3>
            <p><strong>7.1</strong> Access to the Platform, or to certain features of the Service, may require you to pay fees. Before you pay any fees, you will have an opportunity to review and accept the fees that you will be charged. All fees are in U.S. dollars and are non-refundable.</p>
            <p><strong>7.2</strong> DolphinReader reserves the right to determine pricing for the Service. DolphinReader will make reasonable efforts to keep pricing information published on the website up to date. We encourage you to check our website periodically for current pricing information.</p>
            <p><strong>7.3</strong> DolphinReader will charge the payment method you specify at the time of purchase. You authorize DolphinReader to charge to that payment method all sums for orders that you make and any level of Service you select as described in these Terms or published by the Company. If you pay any fees with a credit card, DolphinReader may seek pre-authorization of your credit card account prior to your purchase to verify that the credit card is valid and has the necessary funds or credit available to cover your purchase. Payment processing services are provided by the third-party service through which the purchase is made (e.g., Apple In-App Purchases, Google Play Payments, PayPal Payments).</p>
            <p><strong>7.4</strong> All purchases may be subject to taxes and other fees, including, without limitation, foreign exchange fees or differences in prices based on location (e.g. exchange rates). We may calculate taxes payable by you based on the information that you provide us via your Account or at the time or purchase.</p>

            <h3>8. Disclaimers</h3>
            <p><strong>8.1</strong> The Platform is made available “as is”, “as available”, and “with all faults” for the stated purposes herein. Use of the Platform is entirely at your own risk and you should use your best judgment and exercise caution while using the Platform.</p>
            <p><strong>8.2</strong> DolphinReader makes no guarantee that your use of the Platform, and all other features or functionalities associated with the Platform, or delivery or display of the Platform, will be available, uninterrupted, interference free, or error free, or be free from any viruses, worms, or other security intrusions. You understand and agree that you use the Platform, and use, access, download, or otherwise obtain materials or content through the Platform and any associated sites or Platforms, at your own discretion and risk, and that you are solely responsible for any damage to your property (including your computer system or mobile device used in connection with the Platform), or the loss of data that results from the use if the Platform or the download or use of that material or content.</p>
            <p><strong>8.3</strong> DolphinReader does not guarantee the availability, delivery, performance, pricing, or punctuality of any Works or other Intellectual Property appearing on the Platform. Without limitation, this includes making no guarantee that any future Episodes of a Work are posted by a writer or publisher in a timely manner, that Works will remain available on the Platform, or the pricing of Works or Services.</p>
            <p><strong>8.4</strong> DolphinReader assume no responsibility for the deletion or failure to store any Comments or other information submitted by you to the Platform, and we expressly do not promise to store or keep any Comments that you have submitted to the Platform. You are solely responsible for saving backup copies of any Comments that you upload to the Platform.</p>
            <p><strong>8.5</strong> You understand and agree that DolphinReader is not liable for any failure of performance due to any cause beyond its control, including, without limitation, acts of God, fire, explosion, vandalism, terrorism, weather disturbances, national emergencies, riots, wars, labor difficulties, supplier failures, shortages, breaches, action or request by any government, suspension of existing service in compliance with state or federal law, rule, or regulations.</p>

            <h3>9. Limitation of Liability</h3>
            <p>Notwithstanding anything to the contrary contained herein, DolphinReader shall not be liable to you for any direct, indirect, special, incidental, consequential, exemplary, extra-contractual, or punitive damages of any kind whatsoever, which are in any way related to the Platform or these Terms, regardless of legal theory (including, without limitation, contract, tort, personal injury, property damage, negligence, warranty, or strict liability), whether or not DolphinReader has been advised of the possibility or probability of such damages, and even if the remedies otherwise available fail of their essential purposes. If you are dissatisfied with the Platform, or with any of these Terms, or feel DolphinReader has breached these Terms, your sole and exclusive remedy is to discontinue using the Platform. This limitation of liability is part of the basis of the bargain between us.</p>

            <h3>10. Indemnification</h3>
            <p><strong>10.1</strong> You are responsible for your use of the Platform, and you will defend and indemnify DolphinReader and its officers, directors, employees, consultants, affiliates, subsidiaries and agents (together, the “DolphinReader Entities”) from and against every claim, liability, damage, loss, and expense, including reasonable attorneys' fees and costs, arising out of or in any way connected with: (a) your access to, use of, or alleged use of, the Platform; (b) your violation of any portion of these Terms, any representation, warranty, or agreement referenced in these Terms, or any applicable law or regulation; (c) your violation of any third-party right, including any intellectual property right or publicity, confidentiality, other property, or privacy right; or (d) any dispute or issue between you and any third party. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you (without limiting your indemnification obligations with respect to that matter), and in that case, you agree to cooperate with our defense of that claim.</p>
            <p><strong>10.2</strong> DolphinReader reserves the right to take over the exclusive defense of Claims for which DolphinReader is entitled to indemnification, and in such event, you shall provide prompt and reasonable cooperation to DolphinReader at your own expense.</p>

            <h3>11. Promotions</h3>
            <p>In addition to these Terms, a Promotion made available by DolphinReader through the Platform, or otherwise may have specific rules that are different from these Terms. By participating in a Promotion, you agree to and will become subject to those additional terms and conditions, which will be provided to you when a Promotion is offered. DolphinReader urges you to review all rules before you participate in any Promotion. The rules of a specific Promotion will take priority over these Terms in the event of any conflict of the language with a given Promotion.</p>

            <h3>12. Infringement of Intellectual Property Rights</h3>
            <p><strong>12.1</strong> DolphinReader respects the intellectual property rights of others and desires to offer a Platform that contains no material that violates the intellectual property rights of others. If you believe in good faith that your intellectual property rights have been infringed through use on the Platform, you may notify us by email at: dolphinreader1115@gmail.com with “Notice of Infringement” in the subject line, which contains:</p>
            <p className='p-detail'>
                An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright (or other intellectual property) interest;
                <br />
                A description of the copyrighted work (or other intellectual property interest) that you claim has been infringed;
                <br />
                A description specifying the location on the Platform of the material that you claim is infringing;
                <br />
                Your email address and your mailing address and/or telephone number;
                <br />
                A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright (or other intellectual property) owner, its agent, or the law; and
                <br />
                A statement by you made under penalty of perjury, that the information in your notice is accurate and that you are the copyright (or other intellectual property) owner or authorized to act on the copyright (or other intellectual property) owner’s behalf.
            </p>
            <p>If you believe that a notice of intellectual property infringement has been improperly submitted against you, you may submit a “Counter-Notice of Infringement” to DolphinReader, which contains:</p>
            <p>
                Your physical or electronic signature;
                <br />
                Identification of the material removed or to which access has been disabled;
                <br />
                A statement under penalty of perjury that you have a good faith belief that removal or disablement of the material was a mistake or that the material was misidentified;
                <br />
                Your full name, your email address, your mailing address, and a statement that you consent to the jurisdiction of the court (a) in the judicial district where your address is located if the address is in USA, or (b) located in USA, if your address is located outside USA, and that you will accept service of process from the complainant submitting the notice or his/her authorized agent.
            </p>
            <p><strong>12.2</strong> Any notice or counter-notice you submit pursuant to the foregoing must be truthful and must be submitted under penalty of perjury. A false notice or counter-notice may give rise to personal liability. You may therefore want to seek the advice of legal counsel before submitting a notice or a counter-notice. Notwithstanding anything to the contrary stated herein, please note that whether or not DolphinReader disables access to or removes any materials pursuant to the foregoing, DolphinReader may disclose your identity to any third party who has alleged a violation of intellectual property rights hereunder, or supplied us with a counter-notice, as well as disclose the contents of any notice of counter-notice. You acknowledge and agree that any disclosures by us pursuant to the foregoing shall not violate any of your rights, including, without limitation, any rights of privacy that you may have.</p>

            <h3>13. Choice of Law and Dispute Resolution</h3>
            <p><strong>13.1</strong> You consent and agree that the Terms will be exclusively governed by the laws of USA applicable to contracts entered into and performed within USA and notwithstanding any conflict of law principles.</p>
            <p><strong>13.2</strong> You and DolphinReader agree that any dispute, claim, or controversy between you and DolphinReader arising in connection with or relating in any way to these Terms or to your relationship with DolphinReader (whether based in contract, tort, fraud, misrepresentation, or any other legal theory, and regardless if the claims arise during or after the termination of the Terms) will be determined by mandatory binding arbitration. Any arbitration between you and DolphinReader will be resolved by binding arbitration administered by the Singapore International Arbitration Centre (”SIAC”) under the Administrative Arbitration Rules of the USA International Arbitration Centre in force when the notice of arbitration is submitted.</p>

            <h3>14. Notices</h3>
            <p>All legal notices pursuant to the Terms shall be in writing and shall be given by email to DolphinReader at: dolphinreader1115@gmail.com. By using the Platform, you agree that any notice due under the Terms that DolphinReader sends you electronically will satisfy any legal communication or notification. You agree to keep all of your contact information contained in your Account up to date, and we are not responsible in the event you fail to keep your Account or contact information up to date.</p>

            <h3>15. Contact Information</h3>
            <p>The Platform is operating by D.Reader Inc., located at 221 N. Broad Street Suite 3A Middletown, DE, USA 19709. You may contact us by sending correspondence to that address or by emailing us at <a href="">dolphinreader2021@gmail.com</a>.</p>
        </div>
    )
}