import React from 'react'
import { Image } from "antd";
import './CancellationSteps.css'

function CancellationSteps(props) {


    return (
        <div>
            <h1 style={{marginLeft:"70px", marginTop:"32px"}}>Delete Account</h1>
            <br />
            <p style={{marginLeft:"150px", marginRight:"180px", fontSize:"18px", width:"800px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Whenever you desire to delete your account of Dolphin Reader, please follow the steps below. You can find the option within the app, "Me" {">"} "Settings" {">"} "Delete The Account", as the pictures show.</p>
            <br />
            <br />
            <div className="steps_root">
                <img width={180} src='../../step1.png' />
                <img className="arrow-right" src="../../aaa.png"/>
                <img width={180} src='../../step2.png' />
                <img className="arrow-right" src="../../aaa.png"/>
                <img width={180} src='../../step3.png' />
                <img className="arrow-right" src="../../aaa.png"/>
                <img width={180} src='../../step4.png' />
            </div>
            
        </div>
    )
}
export default CancellationSteps